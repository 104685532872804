import { Component, useContext } from 'react';
import { Nav } from 'reactstrap';
import { SideMenuItem } from './SideMenuItem';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { UserContext, UserContextState } from 'context/UserContext';
import { urls } from 'logic/urls';
import css from './SideMenu.module.scss';
import { showView } from 'logic/permissions';

type SideMenuInternalProps = {
    tenantContext: TenantContextState,
    userContext: UserContextState
}

class SideMenuInternal extends Component<SideMenuInternalProps, {}> {
    render() {
        const shouldDisplaySubscriptionMenu = this.props.tenantContext.shouldDisplaySubscriptionsMenu();
        const shouldDisplayDashboard = this.props.tenantContext.shouldDisplayDashboard();
        const shouldDisplayMobileRecordingMenu = this.props.tenantContext.shouldDisplayMobileRecordingMenu();
        const shouldDisplayTeamsRecordingMenu = this.props.tenantContext.shouldDisplayTeamsRecordingMenu();
        const shouldDisplayProcessingMenu = this.props.tenantContext.shouldDisplayProcessingMenu();
        const shouldDisplayPortalAccessMenu = this.props.tenantContext.shouldDisplayPortalAccessMenu();
        const shouldDisplayReplayMenu = this.props.tenantContext.shouldDisplayReplayMenu();
        
        const user = this.props.userContext.user;
        const activeView = this.props.userContext.getActiveView();
        
        
        return (
            <div className={css.sideMenu}>
                <Nav vertical={true} tabs={false}>
                    <ul className="navbar-nav flex-grow">                        
                        {(showView(user, "distributors", activeView) && <SideMenuItem text="Distributors" icon="fa fa-address-book" url={urls.distributors}/>)}
                        {(showView(user, "tenants", activeView)) &&<SideMenuItem text="Tenants" icon="fa fa-address-book" url={urls.distributorTenants}/>}
                        {(showView(user, "mobileSettings", activeView) && <SideMenuItem text="Mobile settings" icon="fa fa-mobile" url={urls.distributorMobileSettings}/>)}
                        {(showView(user, "auditTrail", activeView) && <SideMenuItem text="Audit trail" icon="fa fa-book" url={urls.distributorAuditTrail}/>)}                        
                        {showView(user, "subscription", activeView) && shouldDisplaySubscriptionMenu && <SideMenuItem text="Subscription" icon="fa fa-list" url={urls.subscription}/>}
                        {showView(user, "dashboard", activeView) &&shouldDisplayDashboard && <SideMenuItem text="Dashboard" icon="fa fa-dashboard" url={urls.dashboard}/>}
                        {showView(user, "consent", activeView) && <SideMenuItem text="Consent" icon="fa fa-handshake-o" url={urls.consent}/>}
                        {showView(user, "mobileRecording", activeView) && shouldDisplayMobileRecordingMenu && <SideMenuItem text="Mobile recording" icon="fa fa-mobile" url={urls.mobileRecording}/>}
                        {showView(user, "teamsRecording", activeView) &&shouldDisplayTeamsRecordingMenu && <SideMenuItem text="Teams recording" icon="fa fa-cog" url={urls.teamsRecording}/>}                        
                        {showView(user, "processing", activeView) && shouldDisplayProcessingMenu && <SideMenuItem text="Processing" icon="fa fa-bar-chart" url={urls.processing}/>}                        
                        {showView(user, "replay", activeView) && shouldDisplayReplayMenu && <SideMenuItem text="Replay" icon="fa fa-play-circle-o" url={urls.replay}/> }
                        {showView(user, "portalAccess", activeView) && shouldDisplayPortalAccessMenu && <SideMenuItem text="Portal access" icon="fa fa-key" url={urls.portalAccess}/>}                 
                    </ul>
                </Nav>
            </div>
        );
    }
}

// Inject the required contexts.
export const SideMenu = (props: any) => {
    const userContext = useContext(UserContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <SideMenuInternal userContext={userContext} tenantContext={tenantContext} {...props}/>
    )
}