import React from 'react';
import { Table, Alert } from 'reactstrap';
import { DistributorEntry }  from './DistributorEntry'
import { Distributor } from 'logic/apiCalls';

import css from './DistributorsEntries.module.scss';

type DistributorEntriesProps = {
    distributors: Distributor[], 
    showDeleteButton: boolean,
    handleEdit: (distributorToUpdate: Distributor) => void,
    handleDelete: (distributorToDelete: Distributor) => void
}

export const DistributorEntries = ({ distributors, showDeleteButton, handleEdit, handleDelete } : DistributorEntriesProps) => {

    if (distributors.length === 0) {
        return <Alert color='warning'>
            No distributors are configured
        </Alert>
    }

    return (
        <div className={css.distributorTable}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-4">Name</th>
                        <th className="col-4">Azure Id</th>                        
                        <th className="col-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        distributors.map((value) => {
                            return <DistributorEntry key={value.id} distributor={value} showDeleteButton={showDeleteButton} handleEdit={handleEdit} handleDelete={handleDelete} />
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}