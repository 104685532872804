// This file contains the settings of the appConfig that needs to be overridden when the application is run in development.

export const appConfig: any = {
    //clientId: '70af619e-612b-4d3d-8338-0a0a8ce5f178', // Management Portal App Id development
    clientId: '9549fa1f-8261-4190-ba2b-c4a35606d47b', // Management Portal App Id development (KPNT01)
    managementPortalApiAddress: 'http://localhost:9000', // Local Mock-API, started with 'yarn run dev'
    //managementPortalApiAddress: 'https://localhost:44352', // Local Management Portal API running from Visual Studio
    //managementPortalApiAddress: 'https://app-attest-man-api-d01.azurewebsites.net', // Development Management Portal API running in the Azure cloud
    //managementPortalApiAddress: 'https://management-api.attestdevkpnt01.cybertwice.com', // Development KPN Management Portal API running in the Azure cloud
    apiScopes: [
        //'api://24edb518-dd89-4d93-ac4f-6653c7c66a4a/Content.All', // CyberTwice-Management-Api-d01
        'api://74928e36-55da-4cc0-b9e0-5beb8ec2e174/Content.All', // CyberTwice-Management-Api-KPNT01
    ],
    skin: 'kpn'
};
