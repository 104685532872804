import { Component } from 'react';
import { NotificationContextProvider } from './context/NotificationContext';
import UserContextProvider from './context/UserContext';
import { TenantContextProvider } from './context/TenantContext';
import { Route, Switch } from 'react-router';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Home } from './pages/public/Home';
import { KpnHome } from './pages/public/KpnHome';
import { PostLogin } from './pages/auth/PostLogin';
import { PostConsent } from './pages/auth/PostConsent';
import { TenantUnknown } from './pages/auth/TenantUnknown';
import { TenantDisabled } from './pages/auth/TenantDisabled';
import { UserUnauthorized } from './pages/auth/UserUnauthorized';
import { Portal } from './pages/portal/Portal';
import { Landingpage } from './pages/landingpage/Landingpage';
import { NotFound } from './pages/public/NotFound';
import { urls } from './logic/urls';

import * as skinning from './logic/skinning';

import './App.module.scss';
import './utils/arrayExtensions';

export default class App extends Component {
    static displayName = App.name;

    render() {
        const homePage = skinning.isKpn() ?
            <Route exact path={urls.home} component={KpnHome} /> :
            <Route exact path={urls.home} component={Home} />

        return (
            <NotificationContextProvider>
                <UserContextProvider>
                    <TenantContextProvider>
                        <Switch>
                            {homePage}
                            <Route exact path={urls.postLogin} component={PostLogin} />
                            <Route exact path={urls.postConsent} component={PostConsent} />
                            <Route exact path={urls.tenantUnknown} component={TenantUnknown} />
                            <Route exact path={urls.tenantDisabled} component={TenantDisabled} />
                            <Route exact path={urls.userUnauthorized} component={UserUnauthorized} />
                            <ProtectedRoute path='/portal' component={Portal} />
                            <ProtectedRoute path='/landingpage' component={Landingpage} allowUnauthorized="true" />
                            <Route path='*' component={NotFound} />
                        </Switch>
                    </TenantContextProvider>
                </UserContextProvider>
            </NotificationContextProvider>
        );
    }
}
