import { Component, useContext } from 'react';
import { Button } from 'reactstrap';
import { DistributorEntries } from './DistributorEntries';
import { Loader } from 'components/Loader';
import { ModalConfirm } from 'components/ModalConfirm';
import { DoneInfo, ModalDistributorAddOrUpdate} from './ModalDistributorAddOrUpdate'
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { Distributor } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';

import log from 'loglevel';

// The Delete button was considered to rigorous, so instead tenants can now be enabled/disabled.
// However the functionality is kept because it might be useful in test environments.
const showTenantDeleteButton = false;

type DistributorsPageState = {
    isLoading: boolean,
    showApiError: boolean,
    distributors: Distributor[],    
    distributorToDelete: Distributor | null,
    distributorToUpdate: Distributor | null,
    isDeleteModalOpen: boolean,
    isAddOrUpdateModalOpen: boolean
}

type DistributorsInternalProps = {
    notificationContext: NotificationContextState
}

export class DistributorInternal extends Component<DistributorsInternalProps, DistributorsPageState>  {
    state: DistributorsPageState ={
        isLoading: true,
        showApiError: false,
        distributors: [],        
        distributorToDelete: null,
        distributorToUpdate: null,
        isDeleteModalOpen: false,
        isAddOrUpdateModalOpen: false
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    toggleDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    }

    toggleAddOrUpdateModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: !this.state.isAddOrUpdateModalOpen
        });
    }

    openAddModal = () => {
        console.log('openAddModal');
        this.setState({
            isAddOrUpdateModalOpen: true,
            distributorToUpdate: null
        });
    }

    openUpdateModal = (distributorToUpdate: Distributor) => {
        console.log('openUpdateModal');
        console.log(distributorToUpdate);
        this.setState({
            isAddOrUpdateModalOpen: true,
            distributorToUpdate: distributorToUpdate
        });
    }

    openDeleteModal = (distributorToDelete: Distributor) => {
        this.setState({
            isDeleteModalOpen: true,
            distributorToDelete: distributorToDelete
        });
    }

    handleEditButtonClicked = (distributorToUpdate: Distributor) => {        
        this.openUpdateModal(distributorToUpdate);
    }

    handleDeleteButtonClicked = (distributorToDelete: Distributor) => {
        this.openDeleteModal(distributorToDelete);
    }

    loadPageData = async () => {
        try {
            const distributors = await apiCalls.getDistributors();            
console.log(distributors);
            this.setState({
                distributors: distributors,
                isLoading: false
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
        }
    }
  
    executeAddOrUpdate = async (doneInfo: DoneInfo) => {
        log.debug(`[executeAddOrUpdate] Adding/updating distributor ${doneInfo.tenantName} (${doneInfo.azureTenantId})...`);

        try {
            if (doneInfo.isUpdate) {
                await apiCalls.updateDistributor(doneInfo.tenantId, doneInfo.azureTenantId, doneInfo.tenantName);
            }
            else
            {
                await apiCalls.addDistributor(doneInfo.azureTenantId, doneInfo.tenantName);
            }
            
            await this.loadPageData();

            const notificationText = doneInfo.isUpdate ? `Successfully updated distributor info.` : `Successfully added distributor.`;
            this.props.notificationContext.setNotification("Distributor configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the distributor configuration.`, 'danger');
        }
        finally {
            this.toggleAddOrUpdateModal();
        }
    }

    executeDelete = async () => {
        try {
            const distributorToDelete = this.state.distributorToDelete;

            if (distributorToDelete == null) {
                return
            }
    
            const tenantName = distributorToDelete.label;
            const tenantId = distributorToDelete.id;
    
            log.debug(`[executeDelete] Deleting distributor ${tenantName} (${tenantId})...`);

            await apiCalls.deleteTenantByDistributor(distributorToDelete.id);
            await this.loadPageData();

            const notificationText = `Successfully deleted distributor info.`;
            this.props.notificationContext.setNotification("Distributor deleted", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during deleting of the distributor configuration.`, 'danger');
        }
        finally {
            this.toggleDeleteModal();
        }
    }

    renderDistributors = () => {
        return <div className="my-3">
            <DistributorEntries distributors={this.state.distributors} showDeleteButton={showTenantDeleteButton} handleEdit={this.handleEditButtonClicked} handleDelete={this.handleDeleteButtonClicked} />
            <Button className='mt-3' color='primary' onClick={this.openAddModal}>Add distributor</Button>
        </div>
    }

    renderDeleteModal = () => {
        const distributorToDeleteName = this.state.distributorToDelete?.label || '';
        const distributorToDeleteId = this.state.distributorToDelete?.id || '';

        return <ModalConfirm
            headerText="Confirm distributor delete"
            detailedText={`Are you sure you want to delete the distributor '${distributorToDeleteName}' with Id '${distributorToDeleteId}'? Be aware the corresponding tenant is not deleted.`}
            isOpen={this.state.isDeleteModalOpen}
            onToggle={this.toggleDeleteModal}
            onConfirm={this.executeDelete} /> 
    }

    render() {
        return (
            <>
                <h1>Distributors configuration</h1>
                <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                    {this.renderDeleteModal()}
                    <ModalDistributorAddOrUpdate isOpen={this.state.isAddOrUpdateModalOpen} distributorToUpdate={this.state.distributorToUpdate} onToggle={this.toggleAddOrUpdateModal} onAddOrUpdate={this.executeAddOrUpdate}/>
                    {this.renderDistributors()}
                </Loader>
            </>
        );
    }
}

// Inject the required contexts.
export const DistributorsView = (props: any) => {
    const notificationContext = useContext(NotificationContext);

    return (
        <DistributorInternal {...props} notificationContext={notificationContext} />
    )
}