import { Component, createContext, useContext } from 'react';
import { UserContext, UserContextState } from './UserContext';
import { Tenant, Subscription, TeamsConnector, AdminConsent, Connector } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

export type TenantContextState = {
    tenantInfoRetrieved: boolean,
    adminConsents: AdminConsent[],
    isReplayEnabled: boolean,
    connectors: Connector[],
    isRecordingEnabled: boolean,
    subscriptions: Subscription[],
    verifyAdminConsent: () => Promise<void>
    hasTeamsRecordingSubscription: () => boolean,
    shouldDisplayTeamsConsentTab: () => boolean,
    shouldDisplaySubscriptionsMenu: () => boolean,
    shouldDisplayDashboard: () => boolean,
    shouldDisplayMobileRecordingMenu: () => boolean,
    shouldDisplayTeamsRecordingMenu: () => boolean,
    shouldDisplayProcessingMenu: () => boolean,
    shouldDisplayPortalAccessMenu: () => boolean,
    shouldDisplayReplayMenu: () => boolean,
    isTenantInfoRetrieved: () => boolean,
    isAdminConsentGranted: (type: string) => boolean,
    getAdminConsentAppId: (type: string) => string
}

type TenantContextInternalProps = {    
    userContext: UserContextState,
    children: React.ReactNode
}
   
export const TenantContext = createContext<TenantContextState | null>(null);

// Context is responsible for determining multiple configuration values of the current user which 
// needs to be quickly accessible because UI components like the menu are dependent on it.
// The state object has a method to be able to trigger the check at the back-end from outside. This is needed from the 
// 'post-consent' page where the admin consent flag is updated.
class TenantContextProviderInternal extends Component<TenantContextInternalProps, TenantContextState> {
    areSubscriptionsRetrieved: boolean = false;
    isTenantInfoBeingRetrieved: boolean = false;

    state: TenantContextState = {
        tenantInfoRetrieved: false,
        adminConsents: [],
        isRecordingEnabled: false,
        isReplayEnabled: false,
        connectors: [],
        subscriptions: [],
        verifyAdminConsent: () => this.forceRefreshTenantInfo(),
        hasTeamsRecordingSubscription: () => this.hasTeamsRecordingSubscription(),
        shouldDisplayTeamsConsentTab: () => this.shouldDisplayTeamsConsentTab(),
        shouldDisplaySubscriptionsMenu: () => this.shouldDisplaySubscriptionsMenu(),
        shouldDisplayDashboard: () => this.shouldDisplayDashboard(),
        shouldDisplayMobileRecordingMenu: () => this.shouldDisplayMobileRecordingMenu(),
        shouldDisplayTeamsRecordingMenu: () => this.shouldDisplayTeamsRecordingMenu(),
        shouldDisplayProcessingMenu: () => this.shouldDisplayProcessingMenu(),
        shouldDisplayPortalAccessMenu: () => this.shouldDisplayPortalAccessMenu(),
        shouldDisplayReplayMenu: () => this.shouldDisplayReplayMenu(),
        isTenantInfoRetrieved: () => this.state.tenantInfoRetrieved,
        isAdminConsentGranted: (type: string) => this.isAdminConsentGranted(type),
        getAdminConsentAppId: (type: string) => this.getAdminConsentAppId(type)
    }

    componentDidMount() {
        this.retrieveTenantInfo();
        this.getSubscriptions();
    }

    componentDidUpdate() {
        this.retrieveTenantInfo();
        this.getSubscriptions();
    }

    forceRefreshTenantInfo = async () => {
        this.setState({
            tenantInfoRetrieved: false
        }, () => {
            this.retrieveTenantInfo();
        });
    }

    hasTeamsRecordingSubscription = () => {
        return this.state.subscriptions.filter(item => item.subscriptionType.toLowerCase() === "teams").length > 0;
    }

    hasMobileRecordingConnector = () => {
        return this.state.connectors.some(connector => connector.name === "Mobile" && connector.enabled);
    }

    hasCyberGateRecordingConnector = () => {
        return this.state.connectors.some(connector => connector.name === "CyberGate" && connector.enabled);
    }

    hasTeamsRecordingConnector = () => {
        return this.state.connectors.some(connector => connector.name === "Teams" && connector.enabled);
    }

    hasSubscriptionCreatedByMarketplaceOrManually = () => {
        return this.state.subscriptions.filter(item => item.subscriptionCreationSource.toLowerCase() === "manual" || item.subscriptionCreationSource.toLowerCase() === "marketplace").length > 0;
    }

    shouldDisplaySubscriptionsMenu = () => {
        return this.hasSubscriptionCreatedByMarketplaceOrManually();
    }

    shouldDisplayDashboard = () => {
        return true;
    }

    shouldDisplayMobileRecordingMenu = () => {
        return this.hasMobileRecordingConnector();
    }

    shouldDisplayTeamsConsentTab = () => {
        return this.hasTeamsRecordingConnector();
    }

    shouldDisplayTeamsRecordingMenu = () => {
        return this.hasTeamsRecordingSubscription() && 
               this.isAdminConsentGranted("Attest") && 
               this.isAdminConsentGranted("Teams");
    }

    shouldDisplayProcessingMenu = () => {
        return this.hasTeamsRecordingSubscription() || this.hasCyberGateRecordingConnector();
    }

    shouldDisplayPortalAccessMenu = () => {
        return this.isAdminConsentGranted("Attest");
    }

    shouldDisplayReplayMenu = () => {
        return true;
    }

    retrieveTenantInfo = async () => {
        if (this.props.userContext.isAccessAllowed() && !this.isTenantInfoBeingRetrieved) {
            log.debug(`[TenantContextProvider] Access is allowed, retrieving tenant information.`);

            try {
                this.isTenantInfoBeingRetrieved = true;

                const tenantInfo = await apiCalls.getTenant();
                this.extractTenantInfo(tenantInfo);

                const adminConsents = await apiCalls.getAdminConsents();
                this.extractAdminConsents(adminConsents);

                if (tenantInfo.connectors.some(connector => connector.name === "Teams" && connector.enabled))
                {
                    const teamsConnectorInfo = await apiCalls.getTeamsConnector();
                    
                    this.extractRecordingEnabled(teamsConnectorInfo);
                }

                this.setState({
                    tenantInfoRetrieved: true
                }, () => {
                    this.isTenantInfoBeingRetrieved = false;
                });
        
            }
            catch (error) {
                log.error(`Something went wrong during the retrieval of the tenant information: ${error}`);

                this.isTenantInfoBeingRetrieved = false;
            }
        }
    }

    extractAdminConsents = (adminConsents: AdminConsent[]) => {
        this.setState(
            {
                adminConsents: adminConsents
            }
        );
    }

    extractTenantInfo = (tenantInfo: Tenant) => {
        this.setState({
            connectors: tenantInfo.connectors,
            isReplayEnabled: tenantInfo.isReplayEnabled
        }); 
    }

    extractRecordingEnabled = (teamsConnectorInfo: TeamsConnector) => {
        this.setState({
            isRecordingEnabled: teamsConnectorInfo.isRecordingEnabled
        }); 
    }

    isAdminConsentGranted = (type: string) : boolean => {
        if (!this.state.tenantInfoRetrieved) {
            return false;
        }

        var adminConsent = this.state.adminConsents.find(item => item.type === type);
        if (!adminConsent) {
            return false;
        }

        return adminConsent.granted;
    }

    getAdminConsentAppId = (type: string) : string => {
        if (!this.state.tenantInfoRetrieved) {
            return "";
        }

        var adminConsent = this.state.adminConsents.find(item => item.type === type);
        if (!adminConsent) {
            return "";
        }

        return adminConsent.appId;
    }
 

    getSubscriptions = async () => {
        if (this.props.userContext.isAccessAllowed() && !this.areSubscriptionsRetrieved) {
            log.debug(`[TenantContextProvider] Access is allowed, getting the current subscriptions.`);

            this.areSubscriptionsRetrieved = true;

            try {
                const subscriptions = await apiCalls.getSubscriptions();

                if (JSON.stringify(this.state.subscriptions) !== JSON.stringify(subscriptions)) {
                    log.info(`[TenantContextProvider] Updating subscriptions.`);

                    this.setState({
                        subscriptions: subscriptions
                    });
                } 
                else 
                {
                    log.debug(`[TenantContextProvider] No change in cached subscriptions found`);
                }
            }
            catch (error) {
                log.error(`Something went wrong: ${error}`);
            }
        }
    }

    render() {
        return (
            <TenantContext.Provider value={this.state}>
                {this.props.children}
            </TenantContext.Provider>
        )
    }
}

// Inject the required contexts.
export const TenantContextProvider = (props: any) => {
    const userContext = useContext(UserContext);        

    return (
        <TenantContextProviderInternal {...props} userContext={userContext}/>
    )
}