import React from 'react';
import { DeleteButton } from 'components/DeleteButton';
import { EditButton } from 'components/EditButton';
import { Distributor } from 'logic/apiCalls';

type DistributorEntryProps = {
    distributor: Distributor,
    showDeleteButton: boolean,
    handleEdit: (distributorToUpdate: Distributor) => void,
    handleDelete: (distributorToDelete: Distributor) => void
}

export const DistributorEntry = ({ distributor, showDeleteButton, handleEdit, handleDelete } : DistributorEntryProps) => {
    return (
        <tr key={distributor.id} className={`d-flex`}>
            <td className="col-4">{distributor.label}</td>            
            <td className="col-4">{distributor.azureTenantId}</td>                    
            <td className="col-4">
                { showDeleteButton && <DeleteButton className='float-right mr-3' onClick={() => handleDelete(distributor)}/> }
                <EditButton className='float-right mr-3' onClick={() => handleEdit(distributor)}/>
            </td>
        </tr>
    )
}