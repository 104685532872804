import { Component, useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { TabReplayGlobal } from './Global/TabReplayGlobal';
import { TabReplayGroups } from './Groups/TabReplayGroups';
import { TabHeader } from '../TabHeader';

type ReplayProps = {
    tenantContext: TenantContextState
}

type ReplayState = {
    forceRefresh : number
}

class ReplayInternal extends Component<ReplayProps, ReplayState> {
    state: ReplayState = {
        forceRefresh: 0
    }

    handleGroupSettingsOnChange = () => {
        this.setState({
            forceRefresh: (this.state.forceRefresh + 1)
        });
    }

    render() {
        const hasAdminConsent = this.props.tenantContext.isAdminConsentGranted("Attest")

        const tabInfos = hasAdminConsent ? [
            { name: "Groups", tabId: "0" },
            { name: "Global", tabId: "1" }
        ] : [
            { name: "Global", tabId: "0" }
        ];

        const tabReplayGroupsRendered = hasAdminConsent ? 
            <TabPane tabId={"0"}>
                <TabReplayGroups />
            </TabPane> : null;

        return (
            <>
                <h1>Replay settings</h1>

                <TabHeader tabInfos={tabInfos}>   
                    <TabContent>
                        {tabReplayGroupsRendered}
                        <TabPane tabId={hasAdminConsent ? "1" : "0"}>
                            <TabReplayGlobal />
                        </TabPane>
                    </TabContent>    
                </TabHeader>           
            </>
        );
    }
}

// Inject the required contexts.
export const Replay = (props: any) => {
    const tenantContext = useContext(TenantContext);
  
    return (
        <ReplayInternal tenantContext={tenantContext} {...props}/>
    )
}