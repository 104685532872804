import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { DoneButton } from 'components/DoneButton';
import { Distributor } from 'logic/apiCalls';
import * as validation from 'utils/validation';

type ModalDistributorAddOrUpdateProps = {
    isOpen: boolean,
    distributorToUpdate: Distributor | null,    
    onToggle: ToggleCallBack,
    onAddOrUpdate: AddOrUpdateCallback
}

type ModalDistributorAddOrUpdateState = {
    isUpdating: boolean,        
    tenantName: string,
    tenantId: string,    
    azureTenantId: string,
    isEnabled: boolean,
    isBusy: boolean
}

export type DoneInfo = {
    tenantId: string,
    tenantName: string,
    azureTenantId: string,        
    isUpdate: boolean,
    isEnabled: boolean
}

export type ToggleCallBack = () => void

export type AddOrUpdateCallback = (doneInfo: DoneInfo) => void

export class ModalDistributorAddOrUpdate extends Component<ModalDistributorAddOrUpdateProps, ModalDistributorAddOrUpdateState> {
    state : ModalDistributorAddOrUpdateState = {
        isUpdating: false,        
        tenantName: "",
        tenantId: "",
        azureTenantId: "",
        isEnabled: false,
        isBusy: false
    }

    componentDidUpdate(prevProps: ModalDistributorAddOrUpdateProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const isUpdating = !!this.props.distributorToUpdate;
        
        this.setState(
            {
                isUpdating: isUpdating,
                tenantName: this.props.distributorToUpdate?.label || "",
                tenantId: this.props.distributorToUpdate?.id || "",                
                azureTenantId: this.props.distributorToUpdate?.azureTenantId || "",                
                isBusy: false
            }
        );
    }        

    handleDoneButtonClicked = () => {
        const doneInfo: DoneInfo = {
            tenantName: this.state.tenantName,
            tenantId: this.state.tenantId,
            azureTenantId: this.state.azureTenantId,
            isUpdate: this.state.isUpdating,
            isEnabled: this.state.isEnabled
        }

        this.setState({
            isBusy: true
        });

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.setState({            
        });
        this.props.onToggle();
    }

    handleTenantNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tenantName: e.target.value
        })
    }

    handleTenantIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            azureTenantId: e.target.value
        })
    }

    determineAddOrUpdateButtonEnabledState = () : boolean => {

        if (!this.state.tenantName) {
            return false;
        }

        if (!this.isTenantIdValid()) {
            return false;
        }
        
        return true;
    }

    isTenantIdValid = () : boolean => {
        return validation.isValidGuid(this.state.azureTenantId);
    }

    determineAddOrUpdateButtonTooltip = () : string | undefined => {
        if (!this.state.tenantName) {  
            return "Distributor name is required";
        }

        if (!this.isTenantIdValid()) {
            return "The Azure Tenant ID is not valid";
        }

        return undefined;
    }

    renderTenantName = () => {
        return (
            <Form.Group controlId="tenantName">
                <Form.Label>Distributor name</Form.Label>
                <Form.Control autoComplete="off" type="text" placeholder="Enter distributor name" value={this.state.tenantName} onChange={this.handleTenantNameChanged} />
                <Form.Text className="text-muted">
                    This name is used as friendly name
                </Form.Text>
            </Form.Group>
        )
    }

    renderTenantId = () => {
        return (
            <Form.Group controlId="tenantId">
                <Form.Label>Azure Tenant Id</Form.Label>
                <Form.Control autoComplete="off" type="text" placeholder="Azure AD tenant ID" value={this.state.azureTenantId} onChange={this.handleTenantIdChanged} />
                <Form.Text className="text-muted">
                    The ID of the tenant in Azure AD
                </Form.Text>
            </Form.Group>
        )
    }

    render() {
        const doneButtonText = this.state.isUpdating ? ' Update' : ' Add';

        const enableAddOrUpdateButton = this.determineAddOrUpdateButtonEnabledState();
        const addOrUpdateButtonTooltip = enableAddOrUpdateButton ? "" : this.determineAddOrUpdateButtonTooltip();

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Distributor info</ModalHeader>

            <ModalBody>
                <Form className="mt-3">
                    {this.renderTenantName()}
                    {this.renderTenantId()}                    
                </Form>
            </ModalBody>

            <ModalFooter>
                <DoneButton id="distributorDoneButton" buttonText={doneButtonText} disabled={!enableAddOrUpdateButton} disabledTooltip={addOrUpdateButtonTooltip} showBusy={this.state.isBusy} onClick={this.handleDoneButtonClicked} />                
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

