import { InvalidOperationError, ServerErrorResponse } from 'utils/errors';
import { authentication } from './authentication';
import { appConfig } from './appConfigProvider';
import log from 'loglevel';
import { authorization } from './authorization';

export type Tenant = {
    id: string,
    azureTenantId: string, 
    label: string,
    reference: string,
    enabled: boolean,
    defaultRetentionSeconds: number,
    isReplayEnabled: boolean,
    isTranscriptionEnabled: boolean,
    transcriptionLanguage: string,
    connectors: Connector[],
}

export type Distributor = {
    id: string,
    azureTenantId: string, 
    label: string,    
}

export type Connector = {
    name: string,
    enabled: boolean
}

export type MobileRecorder = {
    id: string,
    retired: boolean,
    status: string,
}

export type AuditEventData = {
    name: string,
    fullName: string,
    metaData: AuditMetaData,
    eventData: any
}

export type AuditMetaData = {
    id: string,
    timeStamp: Date,
    operationId : string,
    initiatedBy: AuditOperationBy,
    tenantId: string,
    tenantName: string
}

export type AuditOperationBy = {
    tenantId: string,
    tenantName: string,
    userId: string,
    userName: string
}

export type AdminConsent = {
    type: string,
    granted: boolean,
    appId: string
}

export type ChangeMobileRecorderOperationStateDto = {
    id: string,
    retired: boolean    
}

export type QueryMobilePhoneNumberInfoCommand = {
    mobilePhoneNumber: string
}

export type QueryMobilePhoneNumberResponse = {
    mobilePhoneNumber: string,
    usedByTenantId: string,
    usedByTenantName: string,
    isUsed: boolean
}

export type QueryMobileConnectorInfoCommand = {
    clientCertificateFingerprint: string
}

export type QueryMobileConnectorResponse = {
    clientCertificateFingerprint: string,
    isUsed: boolean
}

export type MobileConnector = {
    clientCertificateFingerprints: Array<string>
}

export type MobileRecordedUser = {
    mobilePhoneNumber: string,
    name: string | null,
    azureAdUserId: string | null,
    recorded: boolean
}

export type TeamsConnector = {
    isRecordingEnabled: boolean,
}

export type TeamsGlobalConfiguration = {
    captureVideo: boolean,
    captureScreenSharing: boolean,
    recordingConfigurationUpToDate: boolean
}

export interface Group {
    id: string,
    name: string,
    type: string
}

export type GlobalReplayConfiguration = {
    globalAdministratorReplayEnabled: boolean    
}

export type TranscriptionSettings = {
    enabled: boolean
    language: string
}

export type TranscriptionLanguage = {
    code: string
    displayName: string
}

export type Subscription = {
    id: string,
    name: string,
    subscriptionType: string,
    offerId: string,
    planId: string,
    subscriptionState: string,
    subscriptionCreationSource: string,
    autoRenew: boolean,
    paymentPeriodEnd: string
    creationTime: string
}

/** Type defines a single definition of a billing metric. */
export type BillingMetricDefinitionDto = {
    metric: string,
    unitOfMeasure: string,
    displayName: string
}

/** Type defines the metric data which is retrieved by the name of the billing metric and returns an array of numbers (one number for each day). */
export type BillingMetricDataDto = {
    [key: string]: number[] | null
}

/** Type defines the metric data as retrieved from the API which also contains some additional info like the start and end dates. */
export type BillingMetricsDto = {
    startDate: string,
    endDate: string,
    totalDays: number,
    tenantId : string,
    data: BillingMetricDataDto
}
  
export async function getVersion() {
    return await executeJsonMethodOnApi('GET', 'version', null);
}

export async function getAuthorize() {
    const tenantId = getAzureTenantIdOrThrow();   

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/authorize`, null);
}

export async function addTenant(tenantLabel: string, email: string, marketplaceToken: string)
{
    const tenantId = getAzureTenantIdOrThrow();

    const body = { 
        "displayName": tenantLabel,
        "email": email
    }

    return await executeMethodOnApi('POST', `tenant/${tenantId}?token=${encodeURIComponent(marketplaceToken)}`, body);
}

export async function getTenant() : Promise<Tenant> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}`, null);
}

export async function getConnectorNames() : Promise<Array<string>> {
    return await executeJsonMethodOnApi('GET', `system/connectors/`, null);
}

export async function getTenantsByDistributor() : Promise<Array<Tenant>> {
    const loggedInTenantId = getAttestTenantIdOrThrow();
    const tenants = await executeJsonMethodOnApi('GET', `distributors/${loggedInTenantId}/tenants/`, null);
    return tenants.sort((a: Tenant, b:Tenant) => a.label.localeCompare(b.label));
}

export async function getDistributors() : Promise<Array<Distributor>> { 
    const distributors = await executeJsonMethodOnApi('GET', `distributors`, null); 
    return distributors.sort((a: Distributor, b:Distributor) => a.label.localeCompare(b.label));
}

export async function addTenantByDistributor(azureTenantId: string | null, tenantLabel: string, tenantReference: string, defaultRetentionSeconds: number, isTranscriptionEnabled: boolean, transcriptionLanguage: string, connectors: Connector[]) : Promise<void> {        
    const body = {        
        id: azureTenantId === "" ? null : azureTenantId,
        label: tenantLabel,
        reference: tenantReference,
        defaultRetentionSeconds: defaultRetentionSeconds,
        isTranscriptionEnabled: isTranscriptionEnabled,
        transcriptionLanguage: transcriptionLanguage,
        connectors: connectors,
        azureTenantId: azureTenantId === "" ? null : azureTenantId,
    }

    const loggedInTenantId = getAttestTenantIdOrThrow();
    await executeMethodOnApi('POST', `distributors/${loggedInTenantId}/tenants/`, body);
}

export async function addDistributor(tenantId: string, tenantLabel: string) : Promise<void> {    
    const body = {        
        label: tenantLabel,
        AzureTenantId: tenantId
    }

    await executeMethodOnApi('POST', `distributors/`, body);
}

export async function updateDistributor(tenantId: string, azureTenantId: string | null, tenantLabel: string | null) : Promise<void> {        
    const body = {
        id: tenantId,
        azureTenantId: azureTenantId === "" ? null : azureTenantId,
        label: tenantLabel
    }
    await executeMethodOnApi('PATCH', `distributors/${tenantId}`, body);
}

export async function updateTenantByDistributor(tenantId: string, azureTenantId: string, tenantLabel: string, tenantReference: string, defaultRetentionSeconds: number, isTranscriptionEnabled: boolean, transcriptionLanguage: string, connectors: Connector[], enabled: boolean) : Promise<void>
{
    const body = {
        label: tenantLabel,
        reference: tenantReference,
        defaultRetentionSeconds : defaultRetentionSeconds,
        isTranscriptionEnabled: isTranscriptionEnabled,
        transcriptionLanguage: transcriptionLanguage,
        connectors: connectors,
        enabled: enabled,
        azureTenantId: azureTenantId === "" ? null : azureTenantId,
    }

    const loggedInTenantId = getAttestTenantIdOrThrow();
    await executeMethodOnApi('PATCH', `distributors/${loggedInTenantId}/tenants/${tenantId}`, body);
}

export async function deleteTenantByDistributor(tenantId: string) : Promise<void> {
    const loggedInTenantId = getAttestTenantIdOrThrow();
    await executeMethodOnApi('DELETE', `distributors/${loggedInTenantId}/tenants/${tenantId}`, null);
}

export async function getMobileRecorders() : Promise<Array<MobileRecorder>> {    
    return await executeJsonMethodOnApi('GET', `system/mobilerecorders`, null);
}

export async function changeMobileRecorderOperationalState(id: string, retired: boolean) : Promise<void> {
    const body: ChangeMobileRecorderOperationStateDto = {
        id: id,
        retired: retired
    }

    const mobileRecorderIdBase64 = btoa(id);
    await executeMethodOnApi('POST', `system/mobilerecorders/${mobileRecorderIdBase64}/changeoperationalstate`, body);
}

export async function getMobileConnectorByDistributor(tenantId: string) : Promise<MobileConnector> {    
    return await executeJsonMethodOnApi('GET', `system/mobileconnectors/tenants/${tenantId}`, null);
}


export async function queryMobileConnectorInfo(clientCertificateFingerprint: string) : Promise<QueryMobileConnectorResponse> {
    const body: QueryMobileConnectorInfoCommand = {
        clientCertificateFingerprint: clientCertificateFingerprint
    }

    return await executeJsonMethodOnApi('POST', `system/mobileconnectors/Fingerprints/query`, body);
}

export async function updateMobileConnectorByDistributor(tenantId: string, clientCertificateFingerprints: Array<string>) {
    const body: MobileConnector = {
        clientCertificateFingerprints: clientCertificateFingerprints
    }

    return await executeMethodOnApi('PATCH', `system/mobileconnectors/tenants/${tenantId}`, body);
}

export async function getMobileRecordedUsersByDistributor(tenantId: string) : Promise<Array<MobileRecordedUser>> {
    return await executeJsonMethodOnApi('GET', `system/mobileconnectors/tenants/${tenantId}/users`, null);
}

export async function queryMobilePhoneNumberInfo(phoneNumber: string) : Promise<QueryMobilePhoneNumberResponse> {
    const body: QueryMobilePhoneNumberInfoCommand = {
        mobilePhoneNumber: phoneNumber
    }

    return await executeJsonMethodOnApi('POST', `system/mobilephonenumbers/query`, body);
}

export async function getMobilePhoneNumbersAsCsv(excelEnhancement: boolean) {
    return await executeBlobMethodOnApi('GET', `system/mobilephonenumbers/csv?excelEnhancement=${excelEnhancement}`);
}

export async function addMobileRecordedUserByDistributor(tenantId: string, phoneNumber: string, name: string, azureUserId: string) : Promise<void> {
    await addOrUpdateMobileRecordedUserByDistributor(tenantId, phoneNumber, name, azureUserId, true, true);
}

export async function updateMobileRecordedUserByDistributor(tenantId: string, phoneNumber: string, name: string, azureUserId: string, recorded: boolean) : Promise<void> {
    await addOrUpdateMobileRecordedUserByDistributor(tenantId, phoneNumber, name, azureUserId, recorded, false);
}

async function addOrUpdateMobileRecordedUserByDistributor(tenantId: string, phoneNumber: string, name: string, azureUserId: string, recorded: boolean, isAdd: boolean) {
    const body: MobileRecordedUser = {
        mobilePhoneNumber: phoneNumber,
        name: name || null,
        azureAdUserId: azureUserId || null,
        recorded: recorded
    }

    const phoneNumberBase64 = btoa(phoneNumber);
    var method = isAdd ? 'POST' : 'PATCH';
    await executeMethodOnApi(method, `system/mobileconnectors/tenants/${tenantId}/users/${phoneNumberBase64}`, body);
}

export async function deleteMobileRecordedUserByDistributor(tenantId: string, phoneNumber: string) : Promise<void> {
    const phoneNumberBase64 = btoa(phoneNumber);
    await executeMethodOnApi('DELETE', `system/mobileconnectors/tenants/${tenantId}/users/${phoneNumberBase64}`, null);
}

export async function getAuditTrailTenants() : Promise<Array<String>> {
    return await executeJsonMethodOnApi('GET', `system/audittrail/tenants`, null);
}

export async function queryAuditTrail(tenantId: string | null, startDate: Date, skip: number, count: number) : Promise<Array<AuditEventData>> {
    const body = {
        tenantId,
        startDate,
        skip,
        count
    }

    return await executeJsonMethodOnApi('POST', `system/audittrail/query`, body);
}

export async function getMobileRecordedUsers() : Promise<Array<MobileRecordedUser>> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `mobilerecording/${tenantId}/users`, null);
}

export async function updateMobileRecordedUser(phoneNumber: string, name: string, azureUserId: string, recorded: boolean) : Promise<void> {
    const tenantId = getAttestTenantIdOrThrow();

    const body: MobileRecordedUser = {
        mobilePhoneNumber: phoneNumber,
        name: name || null,
        azureAdUserId: azureUserId || null,
        recorded: recorded
    }

    const phoneNumberBase64 = btoa(phoneNumber);    
    await executeMethodOnApi('PATCH', `mobilerecording/${tenantId}/users/${phoneNumberBase64}`, body);
}

export async function getAdminConsents() : Promise<AdminConsent[]> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/adminconsents`, null);
}

export async function setAdminConsent(type: string) : Promise<void> {
    const tenantId = getAttestTenantIdOrThrow();

    const body = {
        type: type
    }

    await executeMethodOnApi('POST', `tenant/${tenantId}/adminconsents`, body);
}

export async function getTeamsConnector() : Promise<TeamsConnector> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `teamsrecording/${tenantId}/connector`, null);
}

export async function updateDefaultRetention(retentionSeconds: number) {
    const tenantId = getAttestTenantIdOrThrow();

    const body = {
        defaultRetentionTimeSeconds: retentionSeconds
    }

    return await executeMethodOnApi('PATCH', `tenant/${tenantId}`, body);
}

export async function getConfigurationScript() {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeBlobMethodOnApi('GET', `teamsrecording/${tenantId}/recordingConfigurationScript`);
}

export async function getTeamsGlobalRecordingConfiguration() : Promise<TeamsGlobalConfiguration> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `teamsrecording/${tenantId}/globalRecordingConfiguration`);
}

export async function updateTeamsGlobalRecordingConfiguration(settings: TeamsGlobalConfiguration) {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeMethodOnApi('PATCH', `teamsrecording/${tenantId}/globalRecordingConfiguration`, settings);
}

export async function getAzureGroups() : Promise<Array<Group>> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/azuregroups`);
}

export async function getTeamsRecordedGroupSettings() {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `teamsrecording/${tenantId}/recordedGroupSettings`);
}

export async function addTeamsRecordingToGroup(groupId: string, isCompliant: boolean) {
    const tenantId = getAttestTenantIdOrThrow();

    const body = { 
        "compliant": isCompliant
    }

    return await executeMethodOnApi('POST', `teamsrecording/${tenantId}/recordedGroupSettings/${groupId}`, body);
}

export async function updateTeamsRecordingGroup(groupId: string, isCompliant: boolean) {
    const tenantId = getAttestTenantIdOrThrow();

    const body = { 
        "compliant": isCompliant
    }

    return await executeMethodOnApi('PATCH', `teamsrecording/${tenantId}/recordedGroupSettings/${groupId}`, body);
}

export async function removeTeamsRecordingFromGroup(groupIdToRemove: string) {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeMethodOnApi('DELETE', `teamsrecording/${tenantId}/recordedGroupSettings/${groupIdToRemove}`);
}

export interface PermissionGroup extends Group {    
    permissions: string[]    
}

interface ApiPermissionGroup extends Group {
    accessPermissions: string[],
    replayPermissions: string[]
}

export async function getReplayPermissionGroups() : Promise<Array<PermissionGroup>> {
    return await getPermissionGroups((group) => group.replayPermissions);
}

export async function getAccessPermissionGroups() : Promise<Array<PermissionGroup>> {
    return await getPermissionGroups((group) => group.accessPermissions);
}

async function getPermissionGroups(permissionSelector: (group : ApiPermissionGroup) => Array<string>) : Promise<Array<PermissionGroup>> {
    const tenantId = getAttestTenantIdOrThrow();

    const apiPermissionGroups = await executeJsonMethodOnApi('GET', `tenant/${tenantId}/permissions/groups`);
    const filteredPermissionGroups = apiPermissionGroups.map((group : ApiPermissionGroup) => {
        return {
            id: group.id,
            name: group.name,
            type: group.type,
            permissions: permissionSelector(group)
        }
    }).filter((group : PermissionGroup) => group.permissions.length > 0);

    return filteredPermissionGroups;
}

export async function setReplayPermissions(groupId: string, permissions: string[]) : Promise<void> {
   await setGroupPermissions(groupId, permissions, "replay");
}

export async function setAccessPermissions(groupId: string, permissions: string[]) : Promise<void> {
    await setGroupPermissions(groupId, permissions, "access");
}

async function setGroupPermissions(groupId: string, permissions: string[], type: string) : Promise<void> {
    const tenantId = getAttestTenantIdOrThrow();

    const body = {
        groupId,
        permissions
    };  

    await executeMethodOnApi('POST', `tenant/${tenantId}/permissions/${type}/groups`, body);
} 

export async function getAvailableReplayPermissions() : Promise<Array<string>>  {
    return await getAvailablePermissions("replay");
}

export async function getAvailableAccessPermissions() : Promise<Array<string>>  {
    return await getAvailablePermissions("access");
}

async function getAvailablePermissions(type: string) : Promise<Array<string>>  {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/permissions/${type}/availablepermissions`);
}

export async function getGlobalReplayConfiguration() : Promise<GlobalReplayConfiguration> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/permissions/replay/global`);
}

export async function updateGlobalReplayConfiguration(settings: GlobalReplayConfiguration) {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeMethodOnApi('PATCH', `tenant/${tenantId}/permissions/replay/global`, settings);
}

export async function getStatistics(period: "lastDay" | "lastWeek" | "lastMonth" | "lastYear") {
    const tenantId = getAttestTenantIdOrThrow();

    const body = {
        period: period
    }

    return await executeJsonMethodOnApi('POST', `tenant/${tenantId}/statistics`, body);
}

export async function getTranscriptionSettings() : Promise<TranscriptionSettings> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/processing/transcriptionSettings`);
}

export async function updateTranscriptionSettings(transcriptionSettings: TranscriptionSettings) {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeMethodOnApi('PATCH', `tenant/${tenantId}/processing/transcriptionSettings`, transcriptionSettings);
}

export async function getTranscriptionLanguages() : Promise<TranscriptionLanguage[]> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/transcriptionlanguages`);
}

// Method returns all subscriptions from the tenant configuration.
export async function getSubscriptions() : Promise<Subscription[]> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/subscriptions`, null);
}

export async function getMetricInfos() : Promise<BillingMetricDefinitionDto[]> {
    const tenantId = getAttestTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/billing/metricinfo`, null);
}

export async function getMetrics(period: "thisMonth" | "lastMonth" | "ytd") : Promise<BillingMetricsDto> {
    const tenantId = getAttestTenantIdOrThrow();
    
    return await executeJsonMethodOnApi('GET', `tenant/${tenantId}/billing/metrics?period=${period}`, null);
}

export async function resolveMarketplaceToken(token: any) {
    const tenantId = getAzureTenantIdOrThrow();
    
    const body = {
        token: token,      
    }

    return await executeJsonMethodOnApi('POST', `marketplace/tenant/${tenantId}/resolvetoken`, body);
}

// Method returns all active (Subscribed and Suspended) subscriptions from the Marketplace 
export async function getActiveSubscriptions() {
    const tenantId = getAzureTenantIdOrThrow();

    return await executeJsonMethodOnApi('GET', `marketplace/tenant/${tenantId}/activeteamssubscriptions`, null);
}

export async function activateSubscription(subscriptionId: string) {
    const tenantId = getAzureTenantIdOrThrow();
    
    return await executeMethodOnApi('POST', `marketplace/tenant/${tenantId}/activatesubscription/${subscriptionId}`, null);
}

function getAzureTenantIdOrThrow() : string {
    const userAccount = authentication.getUserAccount();
    
    if (!userAccount)
    {
        authentication.refreshAuthenticationState();
        throw new InvalidOperationError(undefined, "Logged in user required");
    }

    return userAccount.tenantId;
}

function getAttestTenantIdOrThrow() : string {
    const userAccount = authentication.getUserAccount();
    
    if (!userAccount)
    {
        authentication.refreshAuthenticationState();
        throw new InvalidOperationError(undefined, "Logged in user required");
    }

    const user = authorization.getUser();
    if (!user){
        throw new InvalidOperationError(undefined, "User required");
    }

    if (!user.attestTenantId){
        throw new InvalidOperationError(undefined, "Attest tenant id required");
    }
    
    return user.attestTenantId;
}


async function executeJsonMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null) {
    const response = await executeMethodOnApi(method, relativeApiPath, jsonBody);
    return await response.json();
}

async function executeBlobMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null) {
    const response = await executeMethodOnApi(method, relativeApiPath, jsonBody);
    return await response.blob();
}

async function executeMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null) {  
    try {
        log.debug(`[executeMethodOnApi] Invoking '${method}' on '${relativeApiPath}'...`)

        const accessToken = await authentication.getApiTokenAsync();
    
        const url = `${appConfig.managementPortalApiAddress}/api/${relativeApiPath}`;

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Authorization', `Bearer ${accessToken.accessToken}`);

        const requestParameters: RequestInit = {
            method: method,
            headers: requestHeaders
        };

        // Add the body if one was provided.
        if (jsonBody) {
            requestHeaders.set('Content-Type', 'application/json');
            requestParameters.body = JSON.stringify(jsonBody);
        }
    
        const response = await fetch(url, requestParameters);
    
        if (!response.ok) {
            // Some non-success response received.
            throw new ServerErrorResponse(undefined, response.status, response.statusText, await response.text());
        }
    
        // A success response is received from the back-end.
        return response;
    }
    catch (error: any) {
        if (error instanceof ServerErrorResponse) {
            const responseBody = error.responseBody ? `\r\n${error.responseBody}` : "";
            log.error(`Server error response received while invoking '${method}' on '/${relativeApiPath}' endpoint. Received ${error.statusCode} '${error.statusText}'. ${responseBody}`);
        } else {
            log.error(`Error occurred during while invoking '${method}' on '/${relativeApiPath}': ${error.message}`);    
        }

        throw (error);
    }
}