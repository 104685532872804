import { Component, useContext } from 'react';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { TabHeader } from '../TabHeader';
import { TabContent, TabPane } from 'reactstrap';
import { TabConsentAttest } from './Attest/TabConsentAttest';
import { TabConsentTeamsRecording } from './Teams/TabConsentTeamsRecording'

type ConsentPageInternalProps = {
    tenantContext: TenantContextState,    
}

type ConsentPageState = {
    isLoading: boolean,
    showApiError: boolean
}

export class ConsentPageInternal extends Component<ConsentPageInternalProps, ConsentPageState> {
    render() {
        var shouldDisplayTeamsConsent = this.props.tenantContext.shouldDisplayTeamsConsentTab();

        const tabInfos = [
            { name: "ATTEST", tabId: "0" }
        ]

        if (shouldDisplayTeamsConsent) {
            tabInfos.push({ name: "Teams", tabId: "1" });
        }

        return (
            <>
                <h1>Admin consent</h1>

                <TabHeader tabInfos={tabInfos}>
                    <TabContent>
                        <TabPane tabId="0">
                            <TabConsentAttest />
                        </TabPane>
                        { shouldDisplayTeamsConsent &&
                          <TabPane tabId="1">
                              <TabConsentTeamsRecording />
                          </TabPane> }
                    </TabContent>   
                </TabHeader>
            </>
        );
    }
}

// Inject the required contexts.
export const ConsentPage = (props: any) => {
    const tenantContext = useContext(TenantContext);
  
    return (
        <ConsentPageInternal tenantContext={tenantContext} {...props}/>
    )
}