import { Component, useContext } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './Layout';
import { InitialPage } from './InitialPage';
import { DistributorTenants } from './DistributorTenants/DistributorTenants';
import { DistributorMobileSettings } from './DistributorMobileSettings/DistributorMobileSettings';
import { SubscriptionPage } from './Subscription/SubscriptionPage';
import { DashboardPage } from './Dashboard/DashboardPage';
import { ConsentPage } from './Consent/ConsentPage';
import { MobileRecording } from './MobileRecording/MobileRecording';
import { TeamsRecording } from './TeamsRecording/TeamsRecording';
import { Processing } from './Processing/Processing';
import { PortalAccess } from './PortalAccess/PortalAccess';
import { Replay } from './Replay/Replay';
import { DistributorAuditTrail } from './DistributorAuditTrail/DistributorAuditTrail';
import { NotFound } from '../public/NotFound';
import { AutoLogoff } from 'components/AutoLogoff';
import { UserContext, UserContextState } from 'context/UserContext';
import { urls } from 'logic/urls';
import { DistributorsView } from './DistributorsView/DistributorsView';
import { canAccessView } from 'logic/permissions';

type PortalInternalProps = {    
    userContext: UserContextState    
}

class PortalInternal extends Component<PortalInternalProps, {}> {
    render() {
        const user = this.props.userContext.user;
        

        return (
            <AutoLogoff>
                <Layout>
                    <Switch>
                        <Route exact path={urls.initialPortal} component={InitialPage} />
                        {canAccessView(user, "distributors") && <Route exact path={urls.distributors} component={DistributorsView} />}
                        {canAccessView(user, "tenants") && <Route exact path={urls.distributorTenants} component={DistributorTenants} />}
                        {canAccessView(user, "mobileSettings") && <Route exact path={urls.distributorMobileSettings} component={DistributorMobileSettings} />}
                        {canAccessView(user, "auditTrail") && <Route exact path={urls.distributorAuditTrail} component={DistributorAuditTrail} />}
                        {canAccessView(user, "subscription") && <Route exact path={urls.subscription} component={SubscriptionPage} />}
                        {canAccessView(user, "dashboard") && <Route exact path={urls.dashboard} component={DashboardPage} />}
                        {canAccessView(user, "consent") && <Route exact path={urls.consent} component={ConsentPage} />}
                        {canAccessView(user, "mobileRecording") && <Route exact path={urls.mobileRecording} component={MobileRecording} />}
                        {canAccessView(user, "teamsRecording") && <Route exact path={urls.teamsRecording} component={TeamsRecording} />}
                        <Route exact path={urls.processing} component={Processing} />
                        {canAccessView(user, "portalAccess") && <Route exact path={urls.portalAccess} component={PortalAccess} />}
                        {canAccessView(user, "replay") && <Route exact path={urls.replay} component={Replay} />}

                        <Route path='*' component={NotFound} />
                    </Switch>
                </Layout>
            </AutoLogoff>
        );
    }
}

// Inject the required contexts.
export const Portal = () => {    
    const userContext = useContext(UserContext) as UserContextState;
    
    return (
        <PortalInternal userContext={userContext} />
    )
}