
import { urls } from 'logic/urls'

export type Role = "tenant" | "distributor" | "systemowner"

export class User {
    name: string;
    azureTenantId: string;
    attestTenantId: string;
    roles: Role[];

    constructor(name: string, azureTenantId: string, attestTenantid: string, roles: Role[]) {
        this.name = name;
        this.roles = roles;
        this.azureTenantId = azureTenantId;
        this.attestTenantId = attestTenantid;
    }

    hasRole(role: Role): boolean {
        return this.roles.includes(role);
    }
}


type View = {
    url: string
}
type Views = {
    distributors: View
    tenants: View
    dashboard: View
    mobileSettings: View
    auditTrail: View
    subscription: View
    consent: View
    mobileRecording: View
    teamsRecording: View
    processing: View
    portalAccess: View
    replay: View    
}

const views: Views = {
    distributors: {
        url: urls.distributors
    },
    tenants: {
        url: urls.distributorTenants
    },
    dashboard: {
        url: urls.dashboard
    },
    mobileSettings: {
        url: urls.distributorMobileSettings
    },
    auditTrail: {
        url: urls.distributorAuditTrail
    },
    subscription: {
        url: urls.subscription
    },
    consent: {
        url: urls.consent
    },
    mobileRecording: {
        url: urls.mobileRecording
    },
    teamsRecording: {
        url: urls.teamsRecording
    },
    processing: {
        url: urls.processing
    },
    portalAccess: {
        url: urls.portalAccess
    },
    replay: {
        url: urls.replay
    }    
};

/**
 * This structure defines all the roles and the views they have access to.
 */
const ROLES = {
    systemowner: {
        distributors: true,
        tenants: false,
        dashboard: false,
        mobileSettings: true,
        auditTrail: true,
        subscription: false,
        consent: false,
        mobileRecording: true,
        teamsRecording: false,
        processing: false,
        portalAccess: false,
        replay: false
    },
    distributor: {
        distributors: false,
        tenants: true,
        dashboard: false,
        mobileSettings: false,
        auditTrail: false,
        subscription: false,
        consent: false,
        mobileRecording: false,
        teamsRecording: false,
        processing: false,
        portalAccess: false,
        replay: false
    },
    tenant: {
        distributors: false,
        tenants: false,
        dashboard: true,
        mobileSettings: false,
        auditTrail: false,
        subscription: true,
        consent: true,
        mobileRecording: true,
        teamsRecording: true,
        processing: true,
        portalAccess: true,
        replay: true
    },
}

/**
  * Determines if a user has access to a view. It does not check business logic, like validating is consent is given.
  * @returns boolean which indicates if the user has access to the view.
  */
export function canAccessView<ViewNames extends keyof Views>(
    user: User | null,
    view: ViewNames,
) {
    if (user === null){
        return false;
    }

    return user.roles.some(role => {
        const permission = ROLES[role]?.[view];

        return permission;
    })
}

/**
  * Determines if the specified view should be displayed for the specified user, with the specified active role.
  * @returns boolean which indicates if the specified view should be displayed
  */
export function showView<ViewNames extends keyof Views>(
    user: User | null,
    view: ViewNames,
    activeRole: Role
) {
    if (user === null){
        return false;
    }

    return  ROLES[activeRole]?.[view];    
}

export function getUrlView(view: keyof Views): string {
    return views[view].url;
}