import React, { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext, UserContextState } from 'context/UserContext';
import { Navbar, Nav, NavItem, Button } from 'reactstrap';
import { CompanyLogo } from 'components/CompanyLogo';
import { urls } from 'logic/urls';

import * as skinning from 'logic/skinning';
import css from './PublicLayout.module.scss';

type PublicLayoutInternalProps = {    
    userContext: UserContextState,
    hideHomeButton?: boolean,
    hidePortalButton?: boolean, 
    hideLoginButton?: boolean,
    children: React.ReactNode
}

type PublicLayoutInternalState = {
    redirectToHome: boolean,
    redirectToPortal: boolean
}

class PublicLayoutInternal extends Component<PublicLayoutInternalProps, PublicLayoutInternalState> {
    state = {
        redirectToHome: false,
        redirectToPortal: false
    }

    handleHomeClick = () => {        
        this.setState({
            redirectToHome: true
        });
    }

    handlePortalClick = () => {
        this.setState({
            redirectToPortal: true
        });
    }

    handleLoginClick = () => {
        this.props.userContext.login(null, urls.initialPortal);
    }

    render() {
        const userContext = this.props.userContext;

        if (this.state.redirectToHome) {
            return <Redirect to={urls.home} />
        }

        if (this.state.redirectToPortal) {            
            return <Redirect to={urls.distributorTenants} />
        }

        const homeButtonHidden = this.props.hideHomeButton || userContext.isAccessAllowed() ? css.hidden : "";
        const portalButtonHidden = this.props.hidePortalButton || !userContext.isAccessAllowed() ? css.hidden : "";
        const loginButtonHidden = this.props.hideLoginButton ? css.hidden : "";
        const logoStyling = skinning.isKpn() ? css.logoKpn : css.logoCyberTwice;

        return (
            <div className={css.publicLayout}>
                <CompanyLogo className={logoStyling} />
                <Navbar className="bg-dark navbar-dark flex-row-reverse" >
                    <Nav>
                        <NavItem className={`align-right my-2 mr-3 ${homeButtonHidden}`}>
                            <Button color='secondary' onClick={this.handleHomeClick}>Home</Button>
                        </NavItem>
                        <NavItem className={`align-right my-2 mr-3 ${portalButtonHidden}`}>
                                <Button color='secondary' onClick={this.handlePortalClick}>Portal</Button>
                        </NavItem>
                        <NavItem className={`align-right my-2 mr-3 ${loginButtonHidden}`}>
                            <Button color='primary' onClick={this.handleLoginClick}>Login</Button>
                        </NavItem>
                    </Nav>
                </Navbar>
                {this.props.children}
            </div>
        );
    }
}

type PublicLayoutProps = {
    hideHomeButton?: boolean,
    hidePortalButton?: boolean, 
    hideLoginButton?: boolean,
    children: React.ReactNode
}

// Inject the required contexts.
export const PublicLayout = (props: PublicLayoutProps) => {    
    const userContext = useContext(UserContext) as UserContextState;
    
    return (
        <PublicLayoutInternal {...props} userContext={userContext} hideHomeButton={props.hideHomeButton} hidePortalButton={props.hidePortalButton} hideLoginButton={props.hideLoginButton}/>
    )
}