import * as misc from 'utils/misc';

export const urls = {
    home: '/',
    initialPortal: '/portal/initial',
    distributorTenants: '/portal/distributor/tenants',
    distributors: '/portal/distributors/view',
    distributorMobileSettings: '/portal/distributor/mobilesettings',
    distributorAuditTrail: '/portal/audittrail',
    subscription: '/portal/subscription',
    dashboard: '/portal/dashboard',
    consent: '/portal/consent',
    mobileRecording: '/portal/recording/mobile',
    teamsRecording: '/portal/recording/teams',
    processing: '/portal/processing',
    portalAccess: '/portal/access',
    replay: '/portal/replay',    
    postConsent: '/post-consent',
    postLogin: '/post-login',            
    postLogout: '/',        
    tenantUnknown: '/tenant-unknown',
    tenantDisabled: '/tenant-disabled',
    userUnauthorized: '/user-unauthorized',
    defaultProfilePicture: './images/account.svg',
    powerShellKnowledgeBaseArticle: 'https://support.cybertwice.com/knowledgebase.php?article=43'
} as const;

// Method combines the provided URL fragments and ensures that the fragments are separated
// by only a single slash regardless if the fragment have a slash at the begin or the end.
export function urlCombine(...urlFragments: string[]): string {
    let url = urlFragments[0];

    for (let i = 1; i < urlFragments.length; i++) {
        const urlFragment = urlFragments[i];

        url = misc.trimRight(url, '/') + '/' + misc.trimLeft(urlFragment, '/');
    }

    return url;
}

export function getBaseUriWithoutParameters(): string {
    let url = document.getElementById('root')!.baseURI;
  
    const questionMarkIndex = url.indexOf('?');
    if (questionMarkIndex !== -1) {
      url = url.substring(0, questionMarkIndex);
    }
  
    return url;
}

export function getRelativeUrlWithParameters(): string {
    const originLength = window.location.origin.length;
    const fullUrl = window.location.href;

    return fullUrl.substring(originLength);
}

export function constructAdminConsentUrl(redirectUri: string, state: string, clientId: string): string {
    const redirectUriEncoded = encodeURI(redirectUri);
    const stateEncoded = encodeURI(state);            

    return `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${redirectUriEncoded}&state=${stateEncoded}`;
}

// Returns the value of a query variable in the URL or null when the variable was not found.
export function getQueryVariableFromUrl(variable: string): string | null {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (const varPairs of vars) {
        const pair = varPairs.split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }

    return null;
}