import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { Alert, Spinner, Container, Jumbotron, Button, Form, Row, Col } from 'react-bootstrap'
import { PublicLayout } from '../public/PublicLayout';
import { SpinnerWithDescription } from 'components/SpinnerWithDescription';
import { TenantContext } from 'context/TenantContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';
import { marketPlaceHelper } from 'logic/marketPlaceHelper';
import { urls, getRelativeUrlWithParameters } from 'logic/urls';
import * as apiCalls from 'logic/apiCalls';
import * as graphCalls from 'logic/graphCalls';
import log from 'loglevel';

// ToDo: Handle error during authorization. Now the page is stuck in 'Checking user account...'.

// The possible states that a MarketPlace subscription can have.
const saasSubscriptionStatus = {    
    NOTSTARTED: 0,
    PENDINGFULFILLMENTSTART: 1,
    SUBSCRIBED: 2,
    SUSPENDED: 3,
    UNSUBSCRIBED: 4
}

const initialProcessStatus = {    
    WAITING_FOR_AUTHORIZATION: 0,
    RESOLVING_TOKEN: 1,
    SIGNUP_TENANT: 2,
    CHECKING_EXISTING_SUBSCRIPTIONS: 3,
    SHOW_DETAILS: 4,
    REDIRECT_TO_PORTAL: 5,
    ERROR_TENANT_MISMATCH: 6,
    ERROR_EXISTING_SUBSCRIPTION: 7,
    ERROR_UNEXPECTED: 8
}

const activationProcessStatus = {
    IDLE: 0,
    ACTIVATING: 1,
    ACTIVATED: 2
}

class LandingpageInternal extends Component {
    isLandingspageProcessRunning = false;

    state = {       
        status: initialProcessStatus.WAITING_FOR_AUTHORIZATION,
        activitionStatus: activationProcessStatus.IDLE,
        subscriptionDetails: null,
        existingSubscriptionName: null,
        errorText: null
    }

    componentDidMount() {
        this.verifyAuthorizationState();
    }

    componentDidUpdate() {
        this.verifyAuthorizationState();
    }

    verifyAuthorizationState = () => {
        if (!this.isLandingspageProcessRunning && this.props.userContext.isAuthorizationDone()) {
            this.isLandingspageProcessRunning = true;
            this.runInitialLandingpageProcess();
        }
    }

    runInitialLandingpageProcess = async () => {
        // At this point we have an authenticated user (enforced by the ProtectedRoute component) and
        // the authorization (using a call to the back-end API) is finished. Since the allowUnauthorized flag 
        // has been set on the ProtectedRoute, the current tenant/user might or might not be authorized.
        // In case a user visits the landingpage for the first time, the tenant is unknown and thus will be unauthorized.
        try {
            // Resolve the provided Marketplace token into the subscription details.
            this.setState({
                status: initialProcessStatus.RESOLVING_TOKEN
            });
            const tokenValue = marketPlaceHelper.getMarketPlaceTokenFromUrl();
            const subscriptionDetails = await apiCalls.resolveMarketplaceToken(tokenValue);
            this.setState({
                subscriptionDetails: subscriptionDetails
            });

            // Verify that the tenant Id of the logged-in user match the one in the subscription details.
            const tenantIdFromLoggedInUser = this.props.userContext.getUserAccount().tenantId;
            const tenantIdFromSubscription = subscriptionDetails.beneficiary.tenantId;

            if (tenantIdFromLoggedInUser !== tenantIdFromSubscription) {
                this.setState({
                    status: initialProcessStatus.ERROR_TENANT_MISMATCH
                });

                return;
            }

            // In case the tenant is not known, sign-up the tenant.
            if (!this.props.userContext.isTenantKnown()) {
                this.setState({
                    status: initialProcessStatus.SIGNUP_TENANT
                });

                // Get some more information from the organization and the user.
                const organizationDetails = await graphCalls.invokeMethodWithRetry(() => graphCalls.getOrganizationDetails(), 10, 5 * 1000);
                const tenantLabel = organizationDetails.displayName;
                const userDetails = await graphCalls.invokeMethodWithRetry(() => graphCalls.getUserDetails(), 10, 5 * 1000);
                let userEmail = userDetails.mail;

                if (!userEmail) {
                    // The user does not have a e-mail address configured. Use the e-mail address from the subscription details.
                    userEmail = subscriptionDetails.beneficiary.emailId;
                }

                // Note: The marketplace token is provided to the addTenant method as an additional safe-guard to prevent every tenant can add itself.
                await apiCalls.addTenant(tenantLabel, userEmail, tokenValue);

                // Trigger the authorization again. Now that the tenant has been added, the tenant should be authorized.
                await this.props.userContext.triggerAuthorize();
                if (!this.props.userContext.isTenantAuthorized()) {
                    throw new Error("Something went wrong during the sign-up of the organization. Please try again later.");
                }
            }

            // The user lands on the landingpage for new subscriptions (which have to be activated), but also for existing subscriptions.
            // In the latter case the user should immediately be redirected to the portal.
            if (subscriptionDetails.saasSubscriptionStatus !== saasSubscriptionStatus.PENDINGFULFILLMENTSTART) {
                this.setState({
                    status: initialProcessStatus.REDIRECT_TO_PORTAL
                });

                return;
            }

            // Only a single active subscription is allowed in the ATTEST recorder. Verify if other active subscriptions exists for this tenant
            // with the status Subscribed or Suspended.
            this.setState({
                status: initialProcessStatus.CHECKING_EXISTING_SUBSCRIPTIONS
            });

            const activeSubscriptions = await apiCalls.getActiveSubscriptions();
            if (activeSubscriptions.length > 0) {
                this.setState({
                    status: initialProcessStatus.ERROR_EXISTING_SUBSCRIPTION,
                    existingSubscriptionName: activeSubscriptions[0].name
                });

                return;
            }

            this.setState({
                status: initialProcessStatus.SHOW_DETAILS
            });
        }
        catch (err) {
            this.setState({
                status: initialProcessStatus.ERROR_UNEXPECTED,
                errorText: `${err}`
            });
        }
    }

    getStatusDescription = () => {
        switch (this.state.status) {
            case initialProcessStatus.WAITING_FOR_AUTHORIZATION:
                return "Checking existing user account...";
            case initialProcessStatus.RESOLVING_TOKEN:
                return "Getting subscription details at Microsoft...";
            case initialProcessStatus.SIGNUP_TENANT:
                return "Signing up organization (this can take a while)...";
            case initialProcessStatus.CHECKING_EXISTING_SUBSCRIPTIONS:
                return "Checking existing subscriptions in your organization..."            
            case initialProcessStatus.SHOW_DETAILS:
                return "Showing subscription details before activation"
            case initialProcessStatus.REDIRECT_TO_PORTAL:
                return "Redirecting to the portal"
            case initialProcessStatus.ERROR_TENANT_MISMATCH:
                return "Mismatch between logged-in tenant and subscription tenant"
            case initialProcessStatus.ERROR_EXISTING_SUBSCRIPTION:
                return "Existing active ATTEST subscription has been found"
            case initialProcessStatus.ERROR_UNEXPECTED:
                return "An unexpected error has occurred"
            default:
                return "";
        }
    }

    handleSwitchUserClick = () => {
        const postRedirectUrl = getRelativeUrlWithParameters();

        this.props.userContext.login(null, postRedirectUrl);
    }

    handleActivateSubscriptionClick = async () => {
        try {
            this.setState({
                activitionStatus: activationProcessStatus.ACTIVATING
            });
            const subscriptionId = this.state.subscriptionDetails.id;

            await apiCalls.activateSubscription(subscriptionId);

            this.setState({
                activitionStatus: activationProcessStatus.ACTIVATED
            });

            setTimeout(() => 
            {
                // Forcefully retieve all tenant information again. The tenant should exist now.
                this.props.tenantContext.verifyAdminConsent();

                this.setState({
                    status: initialProcessStatus.REDIRECT_TO_PORTAL
                })
            }, 3000);
        }
        catch (error) {
            log.error(`{error}`);
            this.props.notificationContext.setNotification("Error", `Error during activation of the subscription. Please try again later.`, 'danger');
            this.setState({
                activitionStatus: activationProcessStatus.IDLE
            });
        }
    }

    render() {
        const subscription = this.state.subscriptionDetails;
        const userAccount = this.props.userContext.getUserAccount();
        const isActivating = this.state.activitionStatus === activationProcessStatus.ACTIVATING;
        const isActivated = this.state.activitionStatus === activationProcessStatus.ACTIVATED;

        if (this.state.status === initialProcessStatus.REDIRECT_TO_PORTAL) {
            return <Redirect to={urls.consent} />
        }

        let pageContent = <></>;
        if (this.state.status === initialProcessStatus.ERROR_UNEXPECTED) {
            pageContent = 
                <Alert variant='danger'>
                    {this.state.errorText}
                </Alert>
        } else if (this.state.status === initialProcessStatus.ERROR_TENANT_MISMATCH) {
            pageContent = 
                <Jumbotron className="mt-3">
                    <h1>Organization mismatch detected</h1>
                    <p>The organization of the currently logged-in user does not match the organization that has purchased the subscription.</p>
                    <p>Subscription tenant: '{subscription.beneficiary.tenantId}' ({subscription.beneficiary.emailId})<br/>
                        Logged-in user tenant: '{userAccount.tenantId}' ({userAccount.username}).</p>
                    <p>Please use the button below to log-in with a different user.</p>
                    <Button color='primary' onClick={this.handleSwitchUserClick}>Switch user</Button>
                </Jumbotron>
        } else if (this.state.status === initialProcessStatus.ERROR_EXISTING_SUBSCRIPTION) {         
            pageContent = 
                <Jumbotron className="mt-3">
                    <h1>Existing subscriptions detected</h1>
                    <p>Only a single active subscription is allowed. Please modify the settings of the existing subscription '{this.state.existingSubscriptionName}'
                    instead of creating an additional one.</p>                    
                </Jumbotron>
        } else if (this.state.status !== initialProcessStatus.SHOW_DETAILS ) {
            pageContent = <SpinnerWithDescription description={this.getStatusDescription()} />
        } else {
            pageContent = <div className="mt-3">
                <h1>Subscription details</h1>
                <Form>
                    <Form.Group as={Row} controlId="formSubscriptionName">
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control type="text" readOnly value={subscription.name} />
                        </Col>                            
                    </Form.Group>
                    <Form.Group as={Row} controlId="formSubscriptionPlan">
                        <Form.Label column sm={2}>
                            Plan
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control type="text" readOnly value={subscription.planId} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formSubscriptionInitiator">
                        <Form.Label column sm={2}>
                            Initator
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control type="text" readOnly value={subscription.purchaser.emailId} />
                        </Col>                            
                    </Form.Group>
                    
                    <Alert className="mt-3" variant="info" style={{opacity: 1}}>
                        Please activate the subscription by clicking the 'Activate' button below.<br/>After activation you will be redirected to the management portal where you can configure the ATTEST service.
                    </Alert>
                </Form>
                <Button variant='primary' className='mb-3' onClick={this.handleActivateSubscriptionClick} disabled={isActivating || isActivated}>
                    Activate                    
                </Button>
                <Spinner
                        className="ml-3 mb-2"
                        s="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        hidden={!isActivating}
                    />
                <div>
                    {isActivated ? <h6>Subscription successfully activated!</h6> : null}
                </div>
            </div>
        }

        return (
            <PublicLayout hideHomeButton hidePortalButton hideLoginButton>
                <Container>
                    {pageContent}
                </Container>
            </PublicLayout>
        );
    }
}

// Inject the required contexts.
export const Landingpage = (props) => {
    const userContext = useContext(UserContext);
    const tenantContext = useContext(TenantContext);
    const notificationContext = useContext(NotificationContext);

    return (
        <LandingpageInternal {...props} userContext={userContext} tenantContext={tenantContext} notificationContext={notificationContext}/>
    )
}