import React, { Component, useContext } from 'react';
import { Container, Alert } from 'reactstrap';
import { TopMenu } from './TopMenu';
import { SideMenu } from './SideMenu';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import css from './Layout.module.scss';

type LayoutInternalProps = {
    tenantContext: TenantContextState,
    children: React.ReactNode
}

class LayoutInternal extends Component<LayoutInternalProps, {}> {
  static displayName = LayoutInternal.name;

  render () {
    const tenantContext = this.props.tenantContext;

    let warningRendered = null;    
    if (tenantContext.isTenantInfoRetrieved() && tenantContext.hasTeamsRecordingSubscription() &&
        (!tenantContext.isRecordingEnabled || !tenantContext.isReplayEnabled)) {

        let title = "";
        let subText = "";
        if (tenantContext.isRecordingEnabled) {
            title = "Replay disabled";
            subText = "The replay of the recorded content has been disabled.";
        } else {
            title = tenantContext.isReplayEnabled ? "Recording disabled" : "Recording and replay disabled";
            subText = tenantContext.isReplayEnabled ? "The recording of the content has been disabled." : "The recording and the replay of the content has been disabled.";
        }

        warningRendered = <Alert color='danger'>
            <h1>{title}</h1>
            <p>{subText} Please check your ATTEST subscription status.</p> 
        </Alert>
    }

    return (
      <div className={css.layout}>
        <TopMenu />
        <SideMenu />
        <Container fluid={true}>            
            <div className={css.layoutContent}>
                {warningRendered}
                {this.props.children}
            </div>
        </Container>
      </div>
    );
  }
}

type LayoutProps = {
    children: JSX.Element | JSX.Element[]
}

// Inject the required contexts.
export const Layout = (props: LayoutProps) => {
    const tenantContext = useContext(TenantContext) as TenantContextState;
    
    return (
        <LayoutInternal tenantContext={tenantContext} children={props.children} />
    )
}